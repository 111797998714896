<script setup lang="ts">
import { Section } from "@/components/Ui";
import {
  BlockCardAfterSignup,
  BlockUnlimitedCardsV2,
  // BlockCryptoAndMore,
} from "@/components/Blocks";
import { onUnmounted, onMounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);
const animation = () =>
  gsap.context(() => {
    // Desktop - 1,2 block, mobile - 1 block
    const firstBlocks = isMobile.value
      ? ["#home-first .first"]
      : ["#home-first .first", "#home-first .second"];
    gsap.set(firstBlocks, { y: 500, opacity: 1 });
    firstBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        y: 0,
        duration: 3,
        scrollTrigger: {
          trigger: "#home-hero",
          // pin: true,
          // markers: true,
          scrub: 0.1,
          start: i === 0 ? "-40px top" : "200px top",
          end: isMobile.value ? "200px top" : "500px top",
        },
      });
    });

    // Desktop - 3 block, mobile - 2,3 block
    // const fullBlocks = isMobile.value
    //   ? ["#home-first .second", "#home-first .third"]
    //   : ["#home-first .third"];

    if (isMobile.value) {
      const fullBlocks = ["#home-first .second"];

      gsap.set(fullBlocks, { autoAlpha: 0, y: 50 });
      fullBlocks.forEach((block: any, i: number) => {
        // const animBlocks = gsap.to(block, {
        //   duration: 1,
        //   autoAlpha: 1,
        //   y: 0,
        //   paused: true,
        //   onLeave: () => emit("on-complete")
        // });
        // ScrollTrigger.create({
        //   trigger: block,
        //   end: "-200px top",
        //   once: true,
        //   // markers: true,
        //   // scrub: 0.1,
        //   onEnter: self => {
        //     self.progress === 1 ? animBlocks.progress(1) : animBlocks.play();
        //   }
        // });

        gsap.to(block, {
          autoAlpha: 1,
          duration: 3,
          y: 0,
          scrollTrigger: {
            trigger: block,
            end: "200px bottom",
            // once: true,
            scrub: 0.1,
            // markers: true,
            id: String(i),
          },
        });
      });
    }
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-first"
    :class="[$style.root, $style.section]"
  >
    <div :class="`${$style['root-wrapper']}`">
      <BlockCardAfterSignup
        class="first"
        :class="$style.block"
      />
      <BlockUnlimitedCardsV2
        class="second"
        :class="$style.block"
      />
      <!--      <BlockCryptoAndMore class="third" />-->
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full z-20 h-auto mt-[-86px] md:mt-[-500px];

  &-wrapper {
    @apply w-full grid grid-cols-1 md:gap-[10px];
    @apply md:grid-cols-2;

    & > div {
      @apply col-span-1 opacity-0;
      //&:last-child {
      //  @apply md:col-span-2;
      //}
    }
  }
}
.section {
  @apply px-0 md:px-4;
}
.block {
  @apply rounded-none md:rounded-[40px];
}
</style>
