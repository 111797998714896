<script setup lang="ts">
import { Hero } from "@/components/Ui";
import { useI18n } from "vue-i18n";
import HeroV2BlockSecondV2 from "~/components/Homepage/Desktop/Blocks/HeroV2BlockSecondV2.vue";
import HeroV2BlockFirstV2 from "~/components/Homepage/Desktop/Blocks/HeroV2BlockFirstV2.vue";
import { onEvent } from "@/helpers/events";
import { useUserStore } from "@/store/user";
import { useDictionaryStore } from "@/store/dictionary";

const { t } = useI18n();
const route = useRoute();
const userStore = useUserStore();
const dictionaryStore = useDictionaryStore();

const headingCookieKey = useCookie("promo-heading", {
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  secure: true,
  path: "/",
  decode: (v) => (v ? String(v) : null),
});

const isPromoPage = computed(() => route.meta.page === "homepage-promo");

const headings = [
  "home.heroV2.title",
  "home.heroV2.title-1",
  "home.heroV2.title-2",
  "home.heroV2.title-3",
  "home.heroV2.title-4",
  "home.heroV2.title-5",
  "home.heroV2.title-6",
  "home.heroV2.title-7",
];

const titleKey = computed(() => {
  if (isPromoPage && headingCookieKey.value) {
    const headingIdx = Number(headingCookieKey.value);
    if (
      typeof headingIdx === "number" &&
      typeof headings[headingIdx] === "string"
    ) {
      return headings[headingIdx];
    }
  }
  return headings[0];
});

const setupComponent = async () => {
  if (!isPromoPage.value) return;
  const headingIdx = Number(headingCookieKey.value);
  if (
    typeof headingIdx !== "number" ||
    Number.isNaN(headingIdx) ||
    typeof headings[headingIdx] !== "string"
  ) {
    headingCookieKey.value = String(Math.floor(Math.random() * 8));
  }
};
setupComponent();

// send ampl_exp_caption1 event for /promo page
watch(
  [
    () => userStore.countryCode,
    () => dictionaryStore.isReady,
    headingCookieKey,
    isPromoPage,
  ],
  () => {
    if (
      isPromoPage.value &&
      dictionaryStore.isReady &&
      userStore.countryCode &&
      headingCookieKey.value
    ) {
      const isRestricted = dictionaryStore.blockedCountriesCodes.has(
        userStore.countryCode,
      );
      const eventValue = isRestricted ? "9" : String(headingCookieKey.value);
      if (eventValue !== localStorage.getItem("ampl_exp_caption1")) {
        localStorage.setItem("ampl_exp_caption1", eventValue);
        onEvent("exp_caption1", null, { value: eventValue });
      }
    }
  },
);
</script>
<template>
  <Hero
    id="home-hero"
    :class="heroHome.root"
  >
    <div :class="heroHome.content">
      <h1
        :class="[heroHome.title, heroHome[`title-${$i18n.locale}`]]"
        v-html="t(titleKey)"
      />

      <div :class="heroHome.wrapper">
        <HeroV2BlockFirstV2 class="z-10 md:z-0" />
        <HeroV2BlockSecondV2 class="z-20 md:z-0" />
      </div>
    </div>
  </Hero>
</template>
<style lang="scss" module="heroHome">
.root {
  @apply mx-auto md:pb-0 md:mb-0;
  @apply md:max-w-[1172px] md:pt-5 md:px-4 w-full;
}

.title {
  @apply text-center font-black lg:mb-12 xl:mb-10 text-neutral-800;
  @apply hidden md:block;

  &-en,
  &-id,
  &-da,
  &-et,
  &-hu,
  &-lt,
  &-nb,
  &-ro,
  &-sl,
  &-cs,
  &-el,
  &-fi,
  &-lv,
  &-nl,
  &-sk,
  &-sv,
  &-he,
  &-be,
  &-tr,
  &-ua,
  &-bg,
  &-ru,
  &-fr,
  &-it,
  &-th,
  &-hi,
  &-ar,
  &-ko,
  &-vi,
  &-sw {
    @apply leading-none text-[60px] lg:text-[86px] xl:text-[90px];
  }
  &-bn {
    @apply leading-none text-[50px] lg:text-[68px] xl:text-[78px];
  }
  &-pl {
    @apply leading-none text-[50px] lg:text-[70px] xl:text-[80px];
  }
  &-es,
  &-pt {
    @apply leading-none text-[60px] lg:text-[80px] xl:text-[90px];
  }
  &-de {
    @apply leading-none text-[58px] lg:text-[78px] xl:text-[90px];
  }
  &-ja,
  &-zh {
    @apply text-[60px] lg:text-[76px];
    @apply leading-[1.2];
  }
}

.content {
  @apply flex flex-col items-center gap-10 h-full w-full flex-1;
}

.wrapper {
  @apply flex flex-col md:flex-row md:gap-[10px] w-full flex-1;
}
</style>
