<script setup lang="ts">
import { isAppleDevice } from "@/helpers/utilities";

const links = {
  apple: {
    to: "https://apps.apple.com/app/pst-net-cards-cashback/id6450429721",
    img: "/img/download-on-the-app-store.svg",
  },
  android: {
    to: "https://play.google.com/store/apps/details?id=com.pstnet.app&referrer=landing",
    img: "/img/download-on-google.svg",
  },
};

const isIOS = isAppleDevice();
</script>

<template>
  <div class="container root">
    <div
      class="hidden md:flex w-full relative bg-neutral-100 rounded-[40px] mt-[-76px] lg:mt-[-126px]"
    >
      <div class="w-[50%] pl-10 py-[70px] lg:py-20 flex flex-col gap-8">
        <div class="text-[50px] lg:text-[59px] font-bold leading-[100%]">
          {{ $t("home.mobile.title") }}
        </div>
        <div class="flex gap-2.5">
          <nuxt-link :to="links.apple.to">
            <nuxt-img
              :src="links.apple.img"
              width="144"
            />
          </nuxt-link>
          <nuxt-link :to="links.android.to">
            <nuxt-img
              :src="links.android.img"
              width="159"
            />
          </nuxt-link>
        </div>
      </div>
      <nuxt-img
        class="self-end justify-self-center w-[440px] lg:w-[476px]"
        src="/img/mobile-home.png"
      />
    </div>
    <div
      class="flex flex-col md:hidden mt-[-86px] px-10 pt-7 gap-5 items-center w-full mobile"
    >
      <div class="text-[32px] text-center font-[850] leading-[90%]">
        {{ $t("home.mobile.title.mobile") }}
      </div>
      <nuxt-link
        v-if="isIOS"
        :to="links.apple.to"
      >
        <nuxt-img
          :src="links.apple.img"
          width="132"
        />
      </nuxt-link>
      <nuxt-link
        v-else
        :to="links.android.to"
      >
        <nuxt-img
          :src="links.android.img"
          width="150"
        />
      </nuxt-link>

      <nuxt-img
        class="w-full pt-[7px]"
        :src="
          isIOS ? '/img/mobile-home-apple.png' : '/img/mobile-home-android.png'
        "
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.root {
  @apply w-full px-0 mx-auto md:px-4 justify-center;
}

.mobile {
  background: radial-gradient(
    #ffda6b 0%,
    #ff8d23 16%,
    #ffd2a7 78%,
    #ffffff 100%
  );
  background-position: -75% -100%;
  background-size: 300% 200%;
}
</style>
